// src/components/hooks/useAuth.js

import React, { createContext, useState, useContext, useEffect, useReducer } from "react";
import axios from "axios";

const DEFAULT_STATE = {
    jwt: null,
    user: {},
    loggedIn: false,
    loading: true,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN": {
            const { jwt, user } = action.payload;
            return { ...state, jwt, user, loggedIn: true, loading: false };
        }

        case "LOGOUT": {
            return {
                ...state,
                jwt: null,
                user: {},
                loggedIn: false,
                loading: false,
            };
        }

        case "REGISTER": {
            const { jwt, user } = action.payload;
            return { ...state, jwt, user, loggedIn: true, loading: false };
        }

        case "FORGOT": {
            return {
                ...state,
                jwt: null,
                user: {},
                loggedIn: false,
                loading: false,
            };
        }

        default:
            return DEFAULT_STATE;
    }
};

const AuthContext = createContext(DEFAULT_STATE);

export const AuthProvider = ({ children }) => (
    <AuthContext.Provider value={useReducer(reducer, DEFAULT_STATE)}>
        {children}
    </AuthContext.Provider>
);

const useAuth = () => {
    const [state, dispatcher] = useContext(AuthContext);
    const [loading, setLoading] = useState(true);

    let isAuthenticated = state?.loggedIn && Object.keys(state?.user)?.length > 0;

    // Cargamos de la localStorage
    useEffect(() => {
        const user = window?.localStorage?.getItem("user");
        const payload = window && user && JSON.parse(user);

        if (payload && payload?.jwt && payload?.user) {
            dispatcher({ type: "LOGIN", payload });
        }

        setLoading(false);
    }, [dispatcher, setLoading]);

    const login = async credentials => {
        try {
            setLoading(true);

            const { data: payload } = await axios.post(
                `/.netlify/functions/user-login`,
                credentials,
            );

            dispatcher({ type: "LOGIN", payload });

            // Guardamos el payload en la localStorage
            window && window.localStorage.setItem("user", JSON.stringify(payload));

            setLoading(false);

            return true;
        } catch (e) {
            setLoading(false);
            console.log(e?.message);
            return false;
        }
    };

    const logout = async () => {
        try {
            // Borramos la localStorage
            window && window.localStorage.removeItem("user");
            dispatcher({ type: "LOGOUT" });
        } catch (e) {
            console.log(e?.message);
        }
    };

    const forgot = async email => {
        try {
            setLoading(true);

            const { data: payload } = await axios.post(
                `/.netlify/functions/user-forgot-password`,
                email,
            );

            dispatcher({ type: "FORGOT", payload });

            setLoading(false);

            return true;
        } catch (e) {
            setLoading(false);
            return false;
        }
    };

    const register = async credentials => {
        try {
            setLoading(true);

            const { data: payload } = await axios.post(`/.netlify/functions/user-register`, {
                username: credentials.username,
                email: credentials.email,
                password: credentials.password,
            });

            dispatcher({ type: "REGISTER", payload });

            // Guardamos el payload en la localStorage
            window && window.localStorage.setItem("user", JSON.stringify(payload));

            setLoading(false);

            return true;
        } catch (err) {
            setLoading(false);
            console.log(err?.message);
            return false;
        }
    };

    return { state, isAuthenticated, login, logout, register, forgot, loading };
};

export default useAuth;

// https://blog.logrocket.com/user-registration-authentication-strapi-next-js/
