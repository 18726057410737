import React, { createContext, useState, useEffect } from "react";

const FloatingModeContextProvider = ({ children }) => {
    const [floatingMode, setFloatingMode] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", scrollListener);
        return () => window.removeEventListener("scroll", scrollListener);
    }, []);

    const scrollListener = () => {
        const offset = window.pageYOffset;
        if (offset > 50) {
            setFloatingMode(true);
        } else {
            setFloatingMode(false);
        }
    };

    return (
        <>
            <FloatingModeContext.Provider value={{ floatingMode }}>
                {children}
            </FloatingModeContext.Provider>
        </>
    );
};

const initialState = {
    floatingMode: false,
};

export const FloatingModeContext = createContext(initialState);
export default FloatingModeContextProvider;
