module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-146856567-1","head":false,"anonymize":true,"respectDNT":true,"defer":true,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"iOpos Academia: Tecnología aplicada a las Oposiciones","short_name":"iopos academia","start_url":"/","background_color":"#86bed2","theme_color":"#86bed2","display":"minimal-ui","icon":"static/images/logos/iopos-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9019be885af532a9d2afbb657dd42242"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
