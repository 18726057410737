import React, { createContext, useState, useEffect } from "react";

const IpContextProvider = ({ children }) => {
    const [ip, setIp] = useState(null);
    const [warningIp, setWarningIp] = useState(false);

    useEffect(() => {
        const abortCtrl = new AbortController();
        const opts = { signal: abortCtrl.signal };

        const url = "/.netlify/functions/ip";

        try {
            fetch(url, opts)
                .then(result => result.json())
                .then(data => {
                    setIp(data.ip);
                    setWarningIp(validateIp(data.ip));
                })
                .catch(error => {
                    if (error.name === "AbortError") {
                        console.log("Abort error in ContextIp");
                    } else {
                        console.log(error);
                    }
                });
        } catch (err) {
            setIp("0.0.0.0");
        }

        return () => abortCtrl.abort();
    }, []);

    const validateIp = ip => {
        if (!ip) return false;
        else if (ip.startsWith("17.")) return true;
        else if (ip.startsWith("139.178.")) return true;
        else if (ip.startsWith("192.96.170.")) return true;
        else if (ip.startsWith("144.178.")) return true;
        else if (ip.startsWith("192.12.74.")) return true;
        else if (ip.startsWith("63.92.")) return true;
        else if (ip.startsWith("192.42.249")) return true;
        else if (ip.startsWith("204.79.190.")) return true;
        else if (ip.startsWith("0.0.0.0")) return true;
        //else if (ip.startsWith("185.77.158.")) return true;
        //else if (ip.startsWith("31.222.97.230")) return true;
        else return false;
    };

    return (
        <>
            <IpContext.Provider value={{ ip, warningIp }}>{children}</IpContext.Provider>
        </>
    );
};

const initialState = {
    ip: "0.0.0.0",
    warningIp: false,
};

export const IpContext = createContext(initialState);
export default IpContextProvider;
