import React from "react";
import "firebase/analytics";
import "firebase/firestore";
import FloatingModeProvider from "./src/components/contexts/FloatingModeContext";
import CartContextProvider from "./src/components/contexts/CartContext";
import IpProvider from "./src/components/contexts/IpContext";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import BlockUI from "./src/components/contexts/BlockUIContext";
import { AuthProvider } from "./src/components/hooks/useAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {RecoilRoot} from "recoil";
// const ReactPixel = require("react-facebook-pixel");

export const wrapRootElement = ({ element }) => (
    <RecoilRoot>
        <AuthProvider>
            <IpProvider>
                <BlockUI>
                    <GoogleReCaptchaProvider
                        reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
                    >
                        <FloatingModeProvider>
                            <CartContextProvider>{element}</CartContextProvider>
                        </FloatingModeProvider>
                    </GoogleReCaptchaProvider>
                </BlockUI>
            </IpProvider>
            <ToastContainer />
        </AuthProvider>
    </RecoilRoot>
);
